<template>
  <!-- This component feeds into MobileAgenda -->
  <div>
    <!-- No appointments Msg -->
    <div
      id="no-appointments-msg"
      v-if="!todaysAppointments().length && !$store.state.fetchinAppointments"
      class="py-5 text-center mt-10"
    >
      <p class="font-weight-light mt-5 display-1 grey--text">
        No appointments today
      </p>
      <div class="mt-6">
        <v-btn
          fab
          to="/negapp/calendar"
          color="secondary lighten-2"
          class="ma-3 d-md-none"
        >
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-btn>
        <!-- <v-btn fab dark color="green" @click.stop="viewCreateComponent = !viewCreateComponent">
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->
      </div>
    </div>
    <!-- Timeline -->
    <v-timeline v-if="todaysAppointments().length" dense class="ml-n8">
      <v-timeline-item
        v-for="appointment in todaysAppointments()"
        :key="appointment.OID"
        color="secondary"
        fill-dot
      >
        <!-- Blue time dot on the left -->
        <template v-slot:icon>
          <span class="caption white--text">{{
            appointment.start | getTime
          }}</span>
        </template>

        <div>
          <v-card
            elevation="5"
            min-height="120"
            class="ml-n3 rounded-lg pb-1"
            outlined
          >
            <AppointmentCard
              :appointment="appointment"
              @showAppointment="showAppointment"
              @showPropertyInfo="showPropertyInfo"
              @showPersonInfo="showPersonInfo"
            />
          </v-card>
        </div>
      </v-timeline-item>
    </v-timeline>
    <!-- /Timeline -->
  </div>
</template>

<script>
import AppointmentCard from "@/components/Appointments/AppointmentCard";
// import SalesInstructionsDetails from './AgendaSalesInstructionsDetails'
// import LettingPropertyDetails from './AgendaLettingPropertyDetails'
export default {
  name: "AgendaTimeline",
  components: {
    AppointmentCard,
    // SalesInstructionsDetails,
    // LettingPropertyDetails
  },
  props: {
    todaysAppointments: {
      type: Function,
    },
  },
  data() {
    return {
      salesInstructionDetails: [],
    };
  },
  methods: {
    showAppointment(appointment) {
      this.$emit("showAppointment", appointment);
    },
    // Method emits event to MobileAgenda to then open dialog window
    showPropertyInfo(property, appointmentID, type, feedback) {
      this.$emit("showPropertyInfo", property, appointmentID, type, feedback);
    },
    showPersonInfo(person) {
      this.$emit("showPersonInfo", person);
    },
  },
  computed: {},
  filters: {
    getTime(val) {
      return val.substr(11, 5);
    },
  },
};
</script>

