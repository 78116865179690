<template>
  <div
    class="todays-appointment d-flex flex-column justify-center"
    v-touch="{
      left: () => swipe('Left'),
      right: () => swipe('Right'),
    }"
  >
    <!-- Floating btn -->
    <v-btn
      class=""
      @click.stop="viewCreateComponent = !viewCreateComponent"
      id="addButton"
      fab
      :large="$vuetify.breakpoint.smAndUp"
      bottom
      right
      fixed
      dark
      color="green"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- /Floating btn -->

    <div class="mt-n6 d-flex align-start d-md-none">
      <v-btn small color="primary" outlined class="ml-3" @click="backToToday">
        Today
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        small
        color="secondary lighten-2"
        text
        class=""
        @click="refreshAppointments"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn
        small
        color="secondary lighten-2"
        text
        class=""
        to="/negapp/calendar"
      >
        <v-icon>mdi-calendar-month-outline</v-icon>
      </v-btn>
    </div>

    <!-- Prev / Next buttons -->
    <div class="d-flex align-center mt-1">
      <v-btn small text @click="minusOneDay" color="secondary">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <div>
        <span class="headline grey--text text--darken-1 font-weight-thin">{{
          currentDate
        }}</span>
        <p
          class="text-center caption grey--text text--darken-1 font-weight-thin"
          v-if="todaysAppointments().length === 1"
        >
          {{ todaysAppointments().length }} appointment
        </p>
        <p
          class="text-center caption grey--text text--darken-1 font-weight-thin"
          v-if="todaysAppointments().length > 1"
        >
          {{ todaysAppointments().length }} appointments
        </p>
        <p
          v-if="!todaysAppointments().length"
          class="text-center caption grey--text text--darken-1 font-weight-thin"
        >
          No appointments
        </p>
      </div>
      <v-spacer></v-spacer>
      <v-btn small text @click="plusOneDay" color="secondary">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <div class="px-1 fixed-wrapper">
      <Timeline
        :todaysAppointments="todaysAppointments"
        @showAppointment="showAppointment"
        @showPropertyInfo="showPropertyInfo"
        @showPersonInfo="showPersonInfo"
      />
    </div>

    <!-- ADD appointment dialog -->
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="viewCreateComponent"
      width="700"
      persistent
    >
      <CreateAppointment
        :selectedStartDate="dateFormatted()"
        @closeCreateComponent="closeCreateComponent"
        @linkPersonError="linkUnlinkError"
        @linkPropertyError="linkUnlinkError"
        v-if="viewCreateComponent"
      />
    </v-dialog>

    <!-- Edit event dialog -->
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="viewEditComponent"
      max-width="650"
      persistent
    >
      <EditAppointment
        @closeEditComponent="closeEditComponent"
        @deleteAppointment="deleteAppointment"
        @linkPersonError="linkUnlinkError"
        @linkPropertyError="linkUnlinkError"
        v-if="viewEditComponent"
        :eventData="eventToEdit"
      />
    </v-dialog>

    <!-- Property info dialog -->
    <PropertyInfoDialog
      :propertyInfoDialog="propertyInfoDialog"
      :selectedProperty="selectedProperty"
      :selectedAppointmentID="selectedAppointmentID"
      :selectedPropertySalesDetails="selectedPropertySalesDetails"
      :selectedPropertyLettingDetails="selectedPropertyLettingDetails"
      :selectedPropertyLettingTenancyDetails="
        selectedPropertyLettingTenancyDetails
      "
      :selectedPropertyFeedback="selectedPropertyFeedback"
      :selectedPropertyType="selectedPropertyType"
      @closePropertyInfo="closePropertyInfo"
      @apiRequestSuccess="apiRequestSuccess"
      @apiRequestError="apiRequestError"
    />

    <!-- Person info dialog -->
    <PersonInfoDialog
      :personInfoDialog="personInfoDialog"
      :personInfoDialogLoading="personInfoDialogLoading"
      :personInfo="personInfo"
      @closePersonInfo="closePersonInfo"
    />

    <!-- Snack Bar -->
    <v-snackbar
      :color="snackbarColor"
      v-model="snackbar"
      :timeout="snackbarTimeout"
    >
      {{ snackbarText }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment/src/moment";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import CreateAppointment from "@/components/Appointments/CreateAppointment";
import EditAppointment from "@/components/Appointments/EditAppointment";
import Timeline from "@/components/Agendas/AgendaTimeline";
import PersonInfoDialog from "@/components/Dialogs/PersonInfoDialog";
import PropertyInfoDialog from "@/components/Dialogs/PropertyInfoDialog";

export default {
  components: {
    CreateAppointment,
    EditAppointment,
    Timeline,
    PersonInfoDialog,
    PropertyInfoDialog,
  },
  props: {
    newFocusDate: {
      type: String,
    },
  },
  data: () => ({
    today: new Date().toISOString().substr(0, 10),
    todayOriginal: new Date().toISOString().substr(0, 10),
    currentDate: "",
    noEventsToday: 0,
    viewCreateComponent: false,
    viewEditComponent: false,
    eventToEdit: null,
    snackbar: false,
    snackbarText: "",
    snackbarTimeout: 2500,
    snackbarColor: "",
    personInfoDialog: false,
    personInfoDialogLoading: false,
    personInfo: "",
    propertyInfoDialog: false,
    selectedProperty: "",
    selectedAppointmentID: "",
    selectedPropertyType: "",
    selectedPropertyFeedback: "",
    selectedPropertySalesDetails: "",
    selectedPropertyLettingDetails: "",
    selectedPropertyLettingTenancyDetails: "",
  }),
  computed: {
    ...mapState(["appointments"]),
    ...mapGetters(["filterTodaysAppointments"]),
  },
  methods: {
    refreshAppointments() {
      this.fetchAppointments();
    },
    showPropertyInfo(property, appointmentID, type, feedback) {
      this.selectedProperty = property;
      this.selectedAppointmentID = appointmentID;
      this.selectedPropertyType = type;
      this.selectedPropertyFeedback = feedback;

      if (type === "letting property") {
        this.$store
          .dispatch("lettingPropertyDetails", property.OID)
          .then(({ data }) => {
            this.selectedPropertyLettingDetails = data;
            this.$store
              .dispatch("propertyTenanciesLatest", property.OID)
              .then(({ data }) => {
                this.selectedPropertyLettingTenancyDetails = data;
                this.propertyInfoDialog = true;
              });
          });
      } else if (type === "sales instruction") {
        this.$store
          .dispatch("salesInstructionsDetails", property.OID)
          .then(({ data }) => {
            this.selectedPropertySalesDetails = data;
            this.propertyInfoDialog = true;
          });
      }
    },
    closePropertyInfo() {
      this.propertyInfoDialog = false;
      this.selectedPropertySalesDetails = "";
      this.selectedPropertyLettingDetails = "";
      this.selectedPropertyLettingTenancyDetails = "";
    },
    apiRequestSuccess() {
      this.closePropertyInfo();
      this.propertyFeedback = "";
      this.snackbar = true;
      this.snackbarColor = "green";
      this.snackbarText = "Property feedback added.";
    },
    apiRequestError() {
      this.closePropertyInfo();
      this.propertyInfoDialog = false;
      this.propertyFeedback = "";
      this.snackbar = true;
      this.snackbarColor = "red";
      this.snackbarText = "Error. Could not add feedback.";
    },
    showPersonInfo(person) {
      if (person.UserType === "Applicant Buyer") {
        this.$store.dispatch("getBuyerDetails", person.OID).then(({ data }) => {
          this.personInfo = data;
          this.personInfoDialog = true;
          this.personInfoDialogLoading = false;
        });
      } else if (person.UserType === "Applicant Tenant") {
        this.$store
          .dispatch("getTenantDetails", person.OID)
          .then(({ data }) => {
            this.personInfo = data;
            this.personInfoDialog = true;
            this.personInfoDialogLoading = false;
          });
      } else {
        this.personInfoDialogLoading = true;
        this.$store
          .dispatch("getPersonInfo", person.OID)
          .then(({ data }) => {
            this.personInfo = data;
            this.personInfoDialog = true;
            this.personInfoDialogLoading = false;
          })
          .catch(() => {
            this.personInfoDialogLoading = false;
            this.personInfoDialog = false;
            this.personInfo = "";
          });
      }
    },
    closePersonInfo() {
      this.personInfoDialog = false;
      this.personInfo = "";
    },
    backToToday() {
      this.today = this.todayOriginal;
      this.todayOriginal = new Date().toISOString().substr(0, 10);
      this.currentDate = moment(this.today).format("ddd Do MMM");
    },
    linkUnlinkError(text) {
      this.snackbar = true;
      this.snackbarColor = "red";
      this.snackbarText = text.response.data;
    },
    dateFormatted() {
      return moment(this.today).format().substr(0, 10);
    },
    deleteAppointment(id) {
      this.$store
        .dispatch("deleteAppointment", id)
        .then(() => {
          this.viewEditComponent = false;
          this.snackbar = true;
          this.snackbarText = "Appointment deleted.";
          this.snackbarColor = "green";
          this.$store.dispatch("fetchAppointments");
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarText = "Sorry. Appointment cannot be deleted!";
          this.snackbarColor = "red";
        });
    },
    swipe(direction) {
      if (direction === "Left") {
        this.plusOneDay();
      }
      if (direction === "Right") {
        this.minusOneDay();
      }
    },
    todaysAppointments() {
      const events = this.appointments.filter((app) => {
        return app.Start.substr(0, 10) === this.today;
      });
      const sortedEvents = events.sort(function (a, b) {
        return new Date(a.Start) - new Date(b.Start);
      });
      return sortedEvents;
    },
    showAppointment(app) {
      this.eventToEdit = app;
      this.viewEditComponent = true;
    },
    plusOneDay() {
      let newDate = moment(this.today, "YYYY-MM-DD")
        .add(1, "day")
        .format("YYYY-MM-DD");
      this.today = newDate;
      this.currentDate = moment(newDate).format("ddd Do MMM");
      this.todaysAppointments();
      this.$emit("newDate", newDate);
    },
    minusOneDay() {
      let newDate = moment(this.today, "YYYY-MM-DD")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      this.today = newDate;
      this.currentDate = moment(newDate).format("ddd Do MMM");
      this.todaysAppointments();
      this.$emit("newDate", newDate);
    },
    closeCreateComponent() {
      this.viewCreateComponent = false;
      this.$store.dispatch("fetchAppointments");
    },
    closeEditComponent() {
      this.$store.dispatch("fetchAppointments").then(() => {
        this.viewEditComponent = false;
      });
    },
    fetchAppointments() {
      this.$store.dispatch("fetchAppointments");
    },
  },
  created() {
    this.currentDate = moment().format("ddd Do MMM");
  },
  filters: {
    getDate(val) {
      return val.substr(0, 10);
    },
  },
  watch: {
    newFocusDate(val) {
      this.today = val;
      this.currentDate = moment(val).format("ddd Do MMM");
      this.todaysAppointments();
    },
  },
};
</script>

<style scoped>
#no-appointment-msg {
  height: 100vh;
}

#no-appointments-msg {
  height: 600px;
}

#chips {
  font-size: 10px;
}

#addButton {
  margin-bottom: 75px;
}

.fixed-wrapper {
  height: 596px !important;
  overflow-y: scroll;
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.fixed-wrapper::-webkit-scrollbar {
  display: none;
}

@media all and (min-width: 699px) {
  .fixed-wrapper {
    height: 100vh;
  }
}
</style>